import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { ContentLoading } from "../../components";
import { GetConfigDataChanges } from "../../common/models/types/GetConfigDataChanges";
import { GET_CONFIG_DATA_CHANGES } from "../../common/models/config";
import { Grid, Hidden, IconButton } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { HistoryView } from "./HistoryView";

import { updateTabContainers } from "../../state/tabsReducer";
import { TabProps } from "../../components/tabs";
import { connect } from "react-redux";

import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";

interface ConfigProps {
  ModifiedDate?: String;
  UserId?: String;
  Data?: Array<ConfigProps>;
}

interface Props {
  ConfigId: String;
  ConfigName: String;
  TabContainerId: string;
  historyTabs?: any;
  dispatch?: Function;
}

const HistoryListComponent = ({ ConfigId, ConfigName, TabContainerId, historyTabs, dispatch }: Props) => {
  const [getConfigDataChange, { data, error, loading, refetch }] = useLazyQuery<GetConfigDataChanges>(GET_CONFIG_DATA_CHANGES, {
    fetchPolicy: "network-only"
  });

  const [config, setConfig] = useState<Array<ConfigProps>>([]);

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch && dispatch(
      updateTabContainers({
        [TabContainerId]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
  };

  const addTab = (title: string, content: JSX.Element, tabId: number) => {
    var currentTabs = historyTabs.tabContainers[TabContainerId].tabs;
    const tabFocus = currentTabs.findIndex((obj: { tabId: string; }) => obj.tabId === `history-${tabId}`);
    if(tabFocus > 0){
      dispatch && dispatch(updateTabContainers({
        [TabContainerId]: {
          tabFocus: tabFocus,
          tabs: currentTabs
        },
      }));
    }
    else{
      dispatch && dispatch(updateTabContainers({
        [TabContainerId]: {
          tabFocus: currentTabs.length,
          tabs: [
            ...currentTabs,
            {
              tabId: `history-${tabId}`,
              title,
              content,
              closeTab: handleCloseTab
            },
          ],
        }
      }));
    }
  }
  
  useEffect(() => {
    if (!data) {
      getConfigDataChange({
        variables: {
          tableName: ConfigName, //ContractRequestSchema
          tableId: ConfigId // 27
        }
      })
    }
    else {
      const cdata: Array<ConfigProps> = JSON.parse(data?.LDPConfigQueryGroup?.GetConfigDataChange ?? "[]") ?? [];
      let rows: Array<ConfigProps & {RowId: number}> = [];
      let d: Array<ConfigProps> = [];
      for (let i = 0; i < cdata.length; i++) {
        d.push(cdata[i]);
        if (i % 2 === 1) {
          rows.push({
            UserId: cdata[i]?.UserId,
            ModifiedDate: cdata[i]?.ModifiedDate,
            Data: d,
            RowId: rows.length + 1,
          });
          d = [];
        }
      }

      if (d.length > 0) {
        rows.push({
          UserId: d[0]?.UserId,
          ModifiedDate: d[0]?.ModifiedDate,
          Data: d,
          RowId: rows.length + 1,
        });
      }

      setConfig(rows);
    }
  }, [data]);

  const columns = [
    {
      name: "RowId",
      label: "Row ID",
      options: {
        filter: true,
      }
    },
    {
      name: "UserId",
      label: "User ID",
      options: {
        filter: true,
      },
    },
    {
      name: "ModifiedDate",
      label: "Changed Date",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value))
      },
    },
    {
      name: "RowId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <IconButton onClick={() => addTab(`History #${value}`, <HistoryView history={tableMeta.tableData[tableMeta.rowIndex]} />, value)}>
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Hidden only="sm">
        <Grid item xs md={6}>
          {" "}
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId={`${TabContainerId}-table`}
          restoreFilters={true}
          title={"Config"}
          data={config}
          columns={columns}
          options={options}
        />
      </Grid>
      {/* <Modal /> */}
    </Grid>
  );
}

export const HistoryList = connect((state: any) => ({
  historyTabs: state.tabsSection,
}), null)(HistoryListComponent);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
