import React, { ReactNode, useEffect, useState } from "react";
import { Tabs, Tab, AppBar, Box, Typography, TabsProps } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

/*
 * 
 *  Sample usage:
 * 
 *     import { useTab } from "tab";
 *
 *     const Demo = () => {
 *        const { TabComponent, TabItem, addTab } = useTab();
 *        const newTabContent = (<div>New tab content</div>);
 * 
 *        return (
 *          <TabComponent>
 *             <TabItem title="Default Tab 1"> 
 *                <button onClick={()=>addTab("New Tab", newTabContent)}>Add</button>             
 *             </TabItem>
 *             <TabItem title="Default Tab 2"> 
 *                Im just another content for 2nd tab             
 *             </TabItem>
 *          </TabComponent>
 *        )
 *     }
 * 
 * 
 *  Sample usage with unique tab title:
 * 
 *     import { useTab } from "tab";
 *
 *     const Demo = () => {
 *        const { TabComponent, TabItem, addTab } = useTab();
 *        const newTabContent = (<div>New tab content</div>)
 * 
 *        return (
 *          <TabComponent duplicate={false}>
 *             <TabItem title="Default Tab 1"> 
 *                <button onClick={()=>addTab("New Tab", newTabContent)}>Add</button>             
 *             </TabItem>
 *             <TabItem title="Default Tab 2"> 
 *                Im just another content for 2nd tab             
 *             </TabItem>
 *          </TabComponent>
 *        )
 *     }
 * 
 * 
 *  Sample usage with unique tab custom attribute:
 * 
 *     import { useTab } from "tab";
 *
 *     const Demo = () => {
 *        const { TabComponent, TabItem, addTab } = useTab();
 *        const newTabContent = () => (<div>New tab content</div>)
 * 
 *        return (
 *          <TabComponent duplicate={false} checkDuplicateBy="id">
 *             <TabItem title="Default Tab 1"> 
 *                <button onClick={()=>addTab("New Tab", newTabContent, { id: 1 })}>Add</button>             
 *             </TabItem>
 *             <TabItem title="Default Tab 2"> 
 *                Im just another content for 2nd tab             
 *             </TabItem>
 *          </TabComponent>
 *        )
 *     }
 * 
 * 
 *   Other functions:
 *    removeTab: (index: Number) => void;
 *    getTabState: () => TabStateProps;
 *    getTabIndexBy: (name: String, search: any) => Number;
 *    setTabFocus: (index: Number) => void;
 *    getTabCurrentIndex: () => Number;
 */

interface TabPanelProps {
  index: Number;
  children?: JSX.Element;
  value: Number;
}

interface TabLabelProps {
  index: Number;
  title: String;
  removable: Boolean;
}

interface TabAttributeProps {
  id?: Number | String;
  removable?: Boolean;
}

interface TabProps {
  title: String;
  content?: JSX.Element;
  attributes?: TabAttributeProps;
}

interface TabStateProps {
  tabs: Array<TabProps>;
  value: Number;
  duplicate: Boolean;
  checkDuplicateBy?: String | Array<String>,
  onChange: Function | undefined;
}

interface TabItemProps {
  children: ReactNode;
  title: String;
  removable: Boolean;
  selected?: Boolean;
}

interface TabComponentProps {
  children?: ReactNode;
  title?: String;
  duplicate?: Boolean;
  checkDuplicateBy?: String | Array<String>;
  onChange?: (state: TabStateProps, index: Number) => void;
}

interface TabInterface {
  TabComponent: (props: TabComponentProps) => JSX.Element;
  TabItem: (props: any) => JSX.Element;
  addTab: (
    title: String,
    content: JSX.Element,
    attributes?: TabAttributeProps | Boolean
  ) => void;
  removeTab: (index: Number) => void;
  getTabState: () => TabStateProps;
  getTabIndexBy: (name: String, search: any) => Number;
  setTabFocus: (index: Number) => void;
  getTabCurrentIndex: () => Number;
  refillTab: (tabs: TabStateProps["tabs"]) => void;
}

interface TabConfigProps {
  tabs?: TabStateProps["tabs"];
}

export const useTab = (props?: TabConfigProps): TabInterface => {
  const classes = useStyles();
  const [state, setState] = useState<TabStateProps>({
    tabs: props?.tabs ?? [],
    value: 0,
    duplicate: true,
    checkDuplicateBy: undefined,
    onChange: undefined
  });

  const refillTab = (tabs: Array<TabStateProps["tabs"]>) => {
    updateState("tabs", tabs);
  }

  const getTabState = () => {
    let currentState = {};

    setState((prevState) => {
      currentState = prevState;
      return prevState;
    });

    return currentState;
  }

  const getTabCurrentIndex = () => {
    let cursor: Number = 0;
    setState((prevState) => {
      cursor = prevState.value;
      return prevState;
    });

    return cursor;
  }

  const getTabIndexBy = (name: String, search: any) => {
    let cursor = 0;
    setState((prevState) => {
      prevState.tabs?.map((it: TabProps, index: number) => {
        if (it?.attributes[name] === undefined) 
          return false;
          
        if (it?.attributes[name] === search)
          cursor = index;
      });

      return prevState;
    });

    return cursor;
  }
  
  const setTabFocus = (index: Number) => setState((prevState) => {
    if (index < 0 || (prevState.tabs.length - 1) < index) 
      return prevState;

    return {
      ...prevState,
      value: index
    }
  });

  const updateState = (key: String | Object, value?: any) => setState((prevState) => {
    return (typeof key === "object") ? { ...prevState, ...key } : { ...prevState, [key]: value };
  });

  const addTab = (title: String, content: JSX.Element, attributes: TabAttributeProps | Boolean) => {
    let newState = {};
    setState((prevState) => {
      let { tabs, value, duplicate, checkDuplicateBy } = prevState;
      let newTab: TabProps | undefined = {
        title, content, attributes: {
          ...attributes,
          removable: true,
        }
      };

      value = tabs.length;
      if (! duplicate) {
        const filteredTabs = tabs.filter((it: TabProps, index: number) => {
          if (checkDuplicateBy === undefined)
            return (it.title === title);

          let count = 0;
          const checker = Array.isArray(checkDuplicateBy) ? checkDuplicateBy : [checkDuplicateBy];
          checker.map((key) => {
            if (it?.attributes[key] !== undefined && attributes[key] !== undefined && it?.attributes[key] === attributes[key])
              ++count;
          });

          return (count === checker.length);
        });

        if (filteredTabs.length > 0) {
          value = tabs.indexOf(filteredTabs?.pop());
          newTab = undefined;
        }
      }

      newState = {
        ...prevState,
        tabs: (! newTab) ? tabs : [...tabs, newTab],
        value
      }

      return newState;
    });

    return newState;
  }

  const removeTab = (index: Number) => setState((prevState) => {
    let {tabs, value} = prevState;
    let cursor: Number = 0;
    if (value > 0 || (tabs.length - 1) === index) 
    {
      cursor = value - 1;
      if (index > value)
        cursor = value;
    }

    tabs.splice(index, 1);
    return {
      ...prevState,
      tabs: [...tabs],
      value: cursor
    }
  });

  const handleChange = (event: React.ChangeEvent<{}>, value: Number) => setState((prevState) => {
    let update = false;

    if (event && event?.target) {
      const role = event.target.getAttribute("role");
    
      if (event.target.tagName === "DIV" || event.target.tagName === "SPAN" || event.target.tagName === "BUTTON")
        update = true;
  
      if (Boolean(role) && role !== "remove-tab")
        update = true;
    }

    if (prevState.onChange !== undefined) 
      prevState.onChange(prevState, value);
    
    return (! update) ? prevState : {
      ...prevState,
      value
    }
  });

  const renderTab = () => {
    let tabs = state.tabs.map(({ title, attributes }: TabProps, key: Number) => (
      <S.Tab
        id={`ldp-tab-${key}`}
        aria-controls={`ldp-tabpanel-${key}`}
        key={key}
        label={<TabLabel title={title} index={key} removable={attributes?.removable ?? false} />}
      />
    ));

    return tabs;
  }

  const renderTabPanel = () => {
    let tabs = state.tabs.map(({ content }: TabProps, key: number) => (
      <TabPanel key={key} value={state.value} index={key}>
        {content}
      </TabPanel>
    ));

    return tabs;
  }

  const initializeTab = (props: TabComponentProps) => {
    let { children, title, duplicate, checkDuplicateBy, onChange } = props;

    if (state.tabs.length > 0)
      return false;

    let tabs = new Array();
    const _addTab = (title: string, content: any, removable: boolean = false) => tabs.push({
      title,
      content,
      attributes: {
        removable
      }
    });

    let value = 0;
    if (!Array.isArray(children)) 
    {
      const isWrapped = children?.type?.displayName === "TabItem" ? true : children?.type?.name === "TabItem" ? true : false;
      _addTab(
        isWrapped ? children?.props?.title : title,
        isWrapped ? children?.props?.children : children,
        false
      );
    }
    else {
      children.map((it, key) => {
        if (it === undefined)
          return;

        if (it?.props?.selected !== undefined && it?.props?.selected === true) {
          value = key;
        }
        _addTab(it?.props?.title, it?.props?.children, it?.props?.removable);
      });
    }

    duplicate = (duplicate === undefined ? true : duplicate);
    updateState({ tabs, value, duplicate, checkDuplicateBy, onChange });
  }

  const TabLabel = ({ title, index, removable }: TabLabelProps) => (
    <Box p={1}>
      {title}
      {(!removable) ? "" : (
        <Typography component="a" className={classes.closeTabButton} role="remove-tab" onClick={() => removeTab(index)}>
          <CancelPresentationIcon role="remove-tab" />
        </Typography>
      )}
    </Box>
  );

  const TabPanel = ({ children, value, index, ...attrs }: TabPanelProps) => (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`ldp-tabpanel-${index}`}
      aria-labelledby={`ldp-tab-${index}`}
      {...attrs}>
      {value === index && children}
    </div>
  );

  const TabItem = ({ children, title, removable, selected }: TabItemProps) => (<br />);

  const tab = (props: TabComponentProps) => {
    if (props.children) {
      initializeTab(props);
    }
    return (
      <div>
        <S.AppBar position="static" color="default">
          <Tabs
            classes={{ indicator: classes.indicator }}
            value={state.value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {renderTab()}
          </Tabs>
        </S.AppBar>
        {renderTabPanel()}
      </div>
    );
  }

  return { TabComponent: tab, TabItem, addTab, removeTab, getTabState, getTabIndexBy, getTabCurrentIndex, setTabFocus, refillTab }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeTabButton: {
      color: "inherit",
      cursor: "pointer",
      padding: 0,
      marginLeft: 10,
      display: "inline-flex",
      outline: 0,
      position: "relative",
      alignItems: "center",
      textAlign: "center",
      overflow: "visible",
      fontSize: "1.5rem",
      flex: "0 0 auto",
      userSelect: "none",
      borderRadius: "50%",
      border: 0,
      verticalAlign: "middle",
      justifyContent: "center",
      textDecoration: "none",
      backgroundColor: "transparent",
      MozAppearance: "none",
      WebkitAppearance: "none",
      WebkitTapHighlightColor: "transparent",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    indicator: {
      backgroundColor: theme.palette.neutral.main,
    },
    tabPanel: {
      backgroundColor: "#E0F1EF",
      padding: theme.spacing(1),
      minHeight: 250
    }
  })
);

const S = {
  Tab: withStyles((theme: Theme) => ({
    root: {
      padding: 0,
      "&$selected": {
        backgroundColor: theme.palette.neutral.main,
        border: `1px solid ${theme.palette.primary.light}`,
        cursor: "text"
      },
    },
    selected: {}
  }))(Tab),
  AppBar: withStyles((theme: Theme) => ({
    root: {
      boxShadow: "none"
    },
  }))(AppBar),
};