import React, { useEffect } from "react";
import {
  Paper, Grid, Hidden,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

import Diff from 'text-diff';

interface Props {
  history: any
}

export const HistoryView = ({ history }: Props) => {
  const classes = useStyles();

  const boolToText = (value: any) => {
    if (typeof value !== 'boolean')
     return value;

    return value ? 'Yes' : 'No';
  }

  const render = (data: any) => {
    const [columnA, columnB] = data;

    let content = new Array();
    for (let key in columnA) 
    {
      const valueA = boolToText(columnA[key]);
      const valueB = boolToText(columnB[key]);
      const isMonetaryValue = key.toLowerCase() === "price";

      content.push(
        <tr key={key} style={{backgroundColor: valueB === valueA ? "auto" : "#E0F1EF"}}>
          <td>{key}</td>
          <td>{isMonetaryValue ? Number(valueA || 0).toFixed(2) : valueA}</td>
          <td>{valueB === valueA ? (isMonetaryValue ? Number(valueB || 0).toFixed(2) : valueB) : (<b style={{textDecoration: "underline"}}>{isMonetaryValue ? Number(valueB || 0).toFixed(2) : valueB}</b>)}</td>
        </tr>
      );
    }    

    return content;
  }

  return (
    <Grid container spacing={1}>
      <Hidden only="sm">
        <Grid item xs md={6}>
          {" "}
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <Paper>
          {history && (
            <div style={{ overflow: "auto", height: "600px" }}>
              <table className={classes.historyTable} cellSpacing={0}>
                <thead>
                  <tr>
                    <th align="left">Field Name</th>
                    <th align="left">Old</th>
                    <th align="left">New</th>
                  </tr>
                </thead>
                <tbody>
                  {render(history.Data)}
                </tbody>
              </table>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    historyTable: {
      width: "100%",
      '& td': {
        width: "40%"
      },
      '& td:first-child': {
        width: "20%"
      },
      '& ins': {
        background: "#e6ffe6"
      },
      '& del': {
        background: "#ffe6e6"
      },
    },
  })
);