import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { Layout, PrivateRoute, ContentContainer } from "../components";
import "react-table/react-table.css";
import { RouteComponentProps } from "@reach/router";

import { useTab } from "../components/tab";
import { HistoryList } from "../views/history/HistoryList";

interface Props {
  ConfigId: String;
  ConfigName: String;
}

const History = (props : Props & RouteComponentProps) => {
  const { TabComponent, TabItem, addTab } = useTab();
  
  return (
    <ContentContainer>  
      <TabComponent duplicate={false}>
        <TabItem title="History">
          <HistoryList addTab={addTab} {...props} />
        </TabItem>
      </TabComponent>
    </ContentContainer>
  );
};

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute component={History} path="/history" pagetitle="LDP History"/>
        <PrivateRoute component={History} path="/history/:ConfigName" pagetitle="LDP History"/>
        <PrivateRoute component={History} path="/history/:ConfigName/:ConfigId" pagetitle="LDP History"/>
      </Router>
    </Layout>
  );
};